import { Keyboard } from 'react-native'
import { useToast as useNBToast } from 'native-base'
import { useDispatch } from 'react-redux'

import { clearListadoStatus } from '../redux/slices/listadoSlice'
import { clearUserStatus } from '../redux/slices/userSlice'

type HookTypes = 'user' | 'listado'
type CleanersTypes = {
  user: () => void
  listado: () => void
}
type StatusTypes = 'loading' | 'success' | 'error' | 'warning' | 'info' | 'idle'

const useToast = (sliceName?: HookTypes) => {
  const toast = useNBToast()
  const dispatch = useDispatch()

  const cleaners: CleanersTypes = {
    user: clearUserStatus,
    listado: clearListadoStatus,
  }

  return (status: StatusTypes, message: string, duration: number = 1850) => {
    if (status !== 'idle' && status !== 'loading' && message) {
      Keyboard.dismiss()
      toast.show({
        title: message,
        id: status,
        status,
        duration,
        onCloseComplete: sliceName
          ? () => dispatch(cleaners[sliceName]())
          : () => toast.close(status),
      })
    }
  }
}

export default useToast
