import { getReducedScore } from '../../utils/scoreUtils'

import { AlcoholemiaState } from '../../types/reduxTypes'

export type AddDrinkPayloadProps = Omit<
  AlcoholemiaState,
  'addedDrinks' | 'firstPartyDrinkTimestamp' | 'score' | 'status' | 'message'
>
interface AddDrinkPayload {
  payload: AddDrinkPayloadProps
}

export type AlcoholemiaSetPayload = {
  payload: number
}

export const addDrinkReducer = (state: AlcoholemiaState, { payload }: AddDrinkPayload) => {
  const { currentDrink } = payload
  let { addedDrinks, score, firstPartyDrinkTimestamp } = state

  // Adds the new drink to the addedDrinks array
  addedDrinks = [...addedDrinks, currentDrink]

  // If the score is zero, resets the firstDrinkTimestamp. 
  if (score === 0) {
    firstPartyDrinkTimestamp = currentDrink.time.timestamp
  }

  return {
    ...state,
    currentDrink,
    addedDrinks,
    firstPartyDrinkTimestamp,
  }
}

export const setAlcoholemiaReducer = (
  state: AlcoholemiaState,
  { payload }: AlcoholemiaSetPayload
) => {
  if (state.addedDrinks[0]) {
    const initialScore = getReducedScore(state.addedDrinks, payload, state.firstPartyDrinkTimestamp)
    
    const currentDate: any = new Date()
    const firstDrinkTimestamp: any = new Date(state.firstPartyDrinkTimestamp)
    const secondsElapsed = (currentDate - firstDrinkTimestamp) / 1000
    const digestedScore = secondsElapsed / 2400

    const alcoholemiaAbstract = initialScore - digestedScore

    state.score = alcoholemiaAbstract < 0 ? 0 : +alcoholemiaAbstract.toFixed(2)
  }
}
