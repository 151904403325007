import { useFonts } from 'expo-font'
import React from 'react'
import { NativeBaseProvider } from 'native-base'

import StoreProvider from './redux/StoreProvider'
import ScreensRoutes from './components/routes/ScreensRoutes'

const App: React.FC = () => {
  const [loaded] = useFonts({
    ptSansNarrow: require('../assets/fonts/PT_Sans_Narrow/PTSansNarrow-Regular.ttf'),
    ptSansNarrowBold: require('../assets/fonts/PT_Sans_Narrow/PTSansNarrow-Bold.ttf'),
    helvetica: require('../assets/fonts/Helvetica/Helvetica.ttf'),
    helveticaBold: require('../assets/fonts/Helvetica/Helvetica-Bold.ttf'),
    orbitron: require('../assets/fonts/Orbitron/Orbitron-Regular.ttf'),
    orbitronBold: require('../assets/fonts/Orbitron/Orbitron-Bold.ttf'),
  })

  if (!loaded) {
    return null
  }

  return (
    <StoreProvider>
      <NativeBaseProvider>
        <ScreensRoutes />
      </NativeBaseProvider>
    </StoreProvider>
  )
}

export default App
