import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useNavigation } from '@react-navigation/core'

import AlcoholemiaBackground from '../atoms/AlcoholemiaBackground'
import AlcoholemiaDivider from '../atoms/AlcoholemiaDivider'
import RegisterForm from '../organisms/RegisterForm'
import Text from '../atoms/Text'

import { useTranslate } from '../../hooks'
import { NavigationTypes } from '../../types/ScreenTypes'
import { isWeb } from '../../utils/platformUtils'
import AlcoholemiaScrollView from '../atoms/AlcoholemiaScrollView'

const styles = StyleSheet.create({
  sloganContainer: {
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 15,
    textShadowColor: 'rgba(255, 255, 255, 0.45)',
    marginBottom: isWeb ? 40 : 0,
    textAlign: 'center',
  },
  sloganText: { color: '#2d99cc' },
  loginSection: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  hyperlinkText: { color: '#2d99cc' },
  divider: {
    padding: 1,
    alignSelf: 'center',
    marginBottom: 15,
    marginTop: 15,
  },
})

/**
 * ### Renders the Register Screen
 */

const Register: React.FC = () => {
  const translate = useTranslate()
  const navigation = useNavigation<NavigationTypes>()

  return (
    <AlcoholemiaBackground>
      <AlcoholemiaScrollView>
        <Text variant="h3" css={styles.sloganContainer}>
          <Text variant="h3" css={styles.sloganText}>
            {translate('Crea una cuenta y comienza a calcular')}{' '}
          </Text>
          <Text variant="h3">{translate('de inmediato')}</Text>
        </Text>
        <RegisterForm />

        <AlcoholemiaDivider style={styles.divider} />

        <View style={styles.loginSection}>
          <Text variant="body">{translate('¿Ya tienes una cuenta?')} </Text>
          <Text
            css={styles.hyperlinkText}
            variant="body"
            onPress={() => navigation.navigate('Login')}
          >
            {translate('Inicia sesión')}
          </Text>
        </View>
      </AlcoholemiaScrollView>
    </AlcoholemiaBackground>
  )
}

export default Register
