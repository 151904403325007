import React from 'react'
import { Controller } from 'react-hook-form'
import { StyleSheet, TextStyle, View } from 'react-native'
import { Picker, PickerProps } from '@react-native-picker/picker'

import Text from './Text'

import { isAndroid, isIOS, isWeb } from '../../utils/platformUtils'
import { SelectOptionsTypes, TextVariantsTypes } from '../../types/CustomComponents'

interface AlcoholemiaSelectProps extends PickerProps {
  form: any
  name: string
  required?: boolean
  options: SelectOptionsTypes[]
  labelCSS?: TextStyle
  label?: string
  itemColor?: 'white' | 'black'
  labelVariant?: TextVariantsTypes
}

const styles = StyleSheet.create({
  pickerContainer: { marginTop: isWeb || isAndroid ? 5 : 0 },
})

/**
 * ### This component renders a select handled by React-Hook-Form with its control attribute.
 *
 * @param form This must be the object that the useForm hook from RHF returns[OBJECT][REQUIRED]
 * @param name The name that the input will have[STRING][REQUIRED]
 * @param required Determinates if this field is required(DEFAULT false)[BOOLEAN]
 * @param options This is an array of objects and must have the following structure: [{ label: string, value: string }], this array is used to show the options of the select component - [ARRAY][REQUIRED]
 * @param label Determinates if the label should be showed[STRING]
 * @param itemColor Determinates the color of each item in the select list(Default "white")[STRING]
 * @param labelVariant Determinates which variant will be used in the input label [STRING]
 * @param labelCSS Determinates the input label styles[OBJECT]
 * @type TextVariantsTypes:
  | 'uber'
  | 'hero'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'body'
  | 'bodyBold'
  | 'bodyBoldUnderlined'
  | 'bodyOrbitron'
  | 'bodyOrbitronBold'
  | 'caption'
  | 'small'
  | 'smallBold'
  | 'smallBoldUnderlined'
  | 'tiny'
  | 'tinyBold'
  | 'tinyItalic'
  | 'tinyUnderlined'
  | 'tinyBoldUnderlined'
 *
 * ### We can pass any other attribute that the "Picker" from "@react-native-picker/picker" supports
 */

const AlcoholemiaSelect: React.FC<AlcoholemiaSelectProps> = ({
  form,
  name,
  required = false,
  options,
  label = '',
  labelCSS = {},
  itemColor = 'white',
  labelVariant = 'body',
  ...alcoholemiaSelectProps
}: AlcoholemiaSelectProps) => {
  const { control } = form
  const { style, ...restPickerProps } = alcoholemiaSelectProps
  return (
    <View>
      {label ? (
        <Text css={labelCSS} variant={labelVariant}>
          {label}
        </Text>
      ) : null}
      <Controller
        control={control}
        rules={{
          required: required,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.pickerContainer}>
            <Picker
              selectedValue={value}
              onValueChange={onChange}
              onBlur={onBlur}
              style={[
                {
                  backgroundColor: isAndroid ? 'white' : '',
                  padding: isWeb ? 3 : 0,
                  bottom: isIOS ? 35 : 3,
                },
                style,
              ]}
              {...restPickerProps}
            >
              {options.map(({ value, label }, index) => (
                <Picker.Item
                  key={index}
                  color={isAndroid ? '' : itemColor}
                  label={label}
                  value={value}
                />
              ))}
            </Picker>
          </View>
        )}
        name={name}
      />
    </View>
  )
}

export default AlcoholemiaSelect
