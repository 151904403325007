import React from 'react'
import { Button, IButtonProps } from 'native-base'

interface AlcoholemiaButtonProps extends IButtonProps {
  title: React.ReactChild | string
}

/**
 *
 * ### Container component for NativeBase Button.
 *
 * @param title Text that are going to be showed in the button[React.ReactChild | string][REQUIRED]
 */

const AlcoholemiaButton = ({ title, ...buttonProps }: AlcoholemiaButtonProps) => {
  return <Button {...buttonProps}>{title}</Button>
}

export default AlcoholemiaButton
