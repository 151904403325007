import React from 'react'
import { useNavigation } from '@react-navigation/native'

import Text from './Text'

import { isWeb } from '../../utils/platformUtils'
import { useTranslate } from '../../hooks'
import { StyleSheet } from 'react-native'

interface GoBackProps {
  label?: string
}

const styles = StyleSheet.create({
  textLink: { padding: isWeb ? 15 : 'auto' },
})

/**
 * ### It shows a text that behaves as button to navigate to the previous screen.
 * #### `Important!` only use it in the header of NativeStack.Screen > options as header, headerLeft or headerRight
 * @param label Text that will be showed - (DEFAULT: 'Volver')[STRING]
 */

const GoBackHeader: React.FC<GoBackProps> = ({ label = 'Volver' }: GoBackProps) => {
  const navigation = useNavigation()
  const translate = useTranslate()

  return (
    <Text variant="body" css={styles.textLink} onPress={() => navigation.goBack()}>
      {translate(label)}
    </Text>
  )
}

export default GoBackHeader
