type FormatClockMethodReturnTypes = {
  hours: string
  minutes: string
  seconds: string
  ampm: string
}

/**
 * ### This function return the hours, minutes, seconds and am or pm text of the clock
 *
 * @param time This should be the return data from `format(getTime(date), 'pp')`(from `date-fns`)
 * and the `date`variable is the result from `new Date()`.[STRING][REQUIRED]
 */

export const formatClock = (time: string): FormatClockMethodReturnTypes => {
  const splittedTime = time.split(':')
  const description = splittedTime[2].split(' ')

  const hours = splittedTime[0]
  const minutes = splittedTime[1]

  const seconds = description[0]
  const ampm = description[1]

  return { hours, minutes, seconds, ampm }
}
