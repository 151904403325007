import React from 'react'
import { StyleSheet, Text as NativeText, TextProps, TextStyle } from 'react-native'

import { TextAlignmentsTypes, TextVariantsTypes } from '../../types/CustomComponents'

interface TextComponentPropsBase extends TextProps {
  children: React.ReactNode
  variant?: TextVariantsTypes
  css?: TextStyle
}

type TextComponentProps = Omit<TextComponentPropsBase, 'style'>

type BaseTypographyTypes = {
  [fontKey: string]: {
    [properties: string]: string | number
  }
}

const textStyles = StyleSheet.create({
  baseCSS: { color: 'white', textAlign: 'auto', width: 'auto' },
})

const baseTypographyStyles: BaseTypographyTypes = StyleSheet.create({
  uber: {
    fontSize: 60,
    fontFamily: 'ptSansNarrow',
    lineHeight: 70,
  },
  hero: {
    fontSize: 46,
    fontFamily: 'ptSansNarrow',
    lineHeight: 50,
  },
  h1: {
    fontSize: 36,
    fontFamily: 'ptSansNarrow',
    lineHeight: 44,
  },
  h2: {
    fontSize: 30,
    fontFamily: 'ptSansNarrow',
    lineHeight: 36,
  },
  h3: {
    fontSize: 26,
    fontFamily: 'ptSansNarrow',
    lineHeight: 28,
  },
  h4: {
    fontSize: 24,
    fontFamily: 'ptSansNarrow',
    lineHeight: 30,
    letterSpacing: 0.5,
  },
  h5: {
    fontSize: 20,
    fontFamily: 'ptSansNarrow',
    lineHeight: 24,
  },
  h6: {
    fontSize: 18,
    fontFamily: 'ptSansNarrow',
    lineHeight: 22,
  },
  h7: {
    fontSize: 16,
    fontFamily: 'ptSansNarrow',
    lineHeight: 20,
  },
  body: {
    fontSize: 14,
    fontFamily: 'helvetica',
    lineHeight: 20,
  },
  bodyBold: {
    fontSize: 14,
    fontFamily: 'helveticaBold',
    lineHeight: 22,
  },
  bodyBoldUnderlined: {
    fontSize: 14,
    fontFamily: 'helveticaBold',
    lineHeight: 22,
    textDecorationLine: 'underline',
  },
  bodyOrbitron: {
    fontSize: 14,
    fontFamily: 'orbitron',
    lineHeight: 20,
  },
  bodyOrbitronBold: {
    fontSize: 14,
    fontFamily: 'orbitronBold',
    lineHeight: 22,
  },
  caption: {
    fontSize: 13,
    fontFamily: 'helvetica',
    lineHeight: 18,
    letterSpacing: 0.3,
  },
  small: {
    fontSize: 12,
    fontFamily: 'helvetica',
    lineHeight: 16,
  },
  smallBold: {
    fontSize: 12,
    fontFamily: 'helvetica',
    lineHeight: 16,
  },
  smallBoldUnderlined: {
    fontSize: 12,
    fontFamily: 'helvetica',
    lineHeight: 16,
    textDecorationLine: 'underline',
  },
  tiny: {
    fontSize: 10,
    fontFamily: 'helvetica',
    lineHeight: 14,
  },
  tinyBold: {
    fontSize: 10,
    fontFamily: 'helvetica',
    lineHeight: 14,
  },
  tinyItalic: {
    fontSize: 10,
    fontFamily: 'helvetica',
    lineHeight: 14,
    fontStyle: 'italic',
  },
  tinyUnderlined: {
    fontSize: 10,
    fontFamily: 'helvetica',
    lineHeight: 14,
    textDecorationLine: 'underline',
  },
  tinyBoldUnderlined: {
    fontSize: 10,
    fontFamily: 'helvetica',
    lineHeight: 14,
    textDecorationLine: 'underline',
  },
})

/**
 * ### This component is able to render the following elements:
 * `TextVariantsTypes`:
  | 'uber'
  | 'hero'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'body'
  | 'bodyBold'
  | 'bodyBoldUnderlined'
  | 'bodyOrbitron'
  | 'bodyOrbitronBold'
  | 'caption'
  | 'small'
  | 'smallBold'
  | 'smallBoldUnderlined'
  | 'tiny'
  | 'tinyBold'
  | 'tinyItalic'
  | 'tinyUnderlined'
  | 'tinyBoldUnderlined'
 * @param variant Defines which kind of element will be rendered - (See `TextVariantsTypes`)(Default: "body")[STRING]`
 * @param css Adds extra styles to this component. - [OBJECT]
 *
 * ### Besides you can pass any other properties that the "Text" component from 'react-native' supports
 */

const Text: React.FC<TextComponentProps> = ({
  children,
  variant = 'body',
  css,
  ...textProps
}: TextComponentProps) => (
  <NativeText style={[textStyles.baseCSS, baseTypographyStyles[variant], css]} {...textProps}>
    {children}
  </NativeText>
)

export default Text
