import { StatusState } from '../../types/reduxTypes'

export const setStatusReducer = function (state: StatusState, { payload }: any) {
  const { description, status } = payload.response.data

  state.status = status
  state.message = description ? description : ''
}

export const clearStatusReducer = function (state: StatusState) {
  state.status = 'idle'
  state.message = ''
}
