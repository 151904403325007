import { NativeModules, Platform } from 'react-native'

// SO
export const isWeb: boolean = Platform.OS === 'web'
export const isIOS: boolean = Platform.OS === 'ios'
export const isAndroid: boolean = Platform.OS === 'android'

// Devices languages
export const webLang: string = isWeb ? navigator.language.split('-')[0] : ''
export const iosLang: string = isIOS ? NativeModules.SettingsManager.settings.AppleLanguages[0] : ''
export const androidLang: string = isAndroid
  ? NativeModules.I18nManager.localeIdentifier.split('_')[0]
  : ''

export const deviceLanguage: string = isWeb ? webLang : isIOS ? iosLang : androidLang

// Enviroment
export const isDevelopment = process.env.NODE_ENV === 'development'
export const isProduction = process.env.NODE_ENV === 'production'
