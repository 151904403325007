import React from 'react'
import { FlatList, HStack, Spacer, VStack } from 'native-base'
import { useSelector } from 'react-redux'
import { getAlcoholemiaState } from '../../redux/slices/alcoholemiaSlice'
import { StyleSheet, View } from 'react-native'
import { format } from 'date-fns'
import { useNavigation } from '@react-navigation/native'

import AlcoholemiaBackground from '../atoms/AlcoholemiaBackground'
import AlcoholemiaBox from '../atoms/AlcoholemiaBox'
import AlcoholemiaButton from '../atoms/AlcoholemiaButton'
import Text from '../atoms/Text'

import { useTranslate } from '../../hooks'

import { CurrentDrink } from '../../types/reduxTypes'
import { NavigationTypes } from '../../types/ScreenTypes'
import AlcoholemiaScrollView from '../atoms/AlcoholemiaScrollView'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 30,
  },
  text: {
    color: 'grey',
  },
  textMarca: {
    color: '#4a4a4a',
  },
  viewContainer: { width: '100%' },
  title: { paddingBottom: 15 },
  textCenter: { textAlign: 'center' },
  homeDesc: { marginBottom: 20, textAlign: 'center' },
  button: { marginTop: 10 },
})

const AddedDrinks = () => {
  const { addedDrinks } = useSelector(getAlcoholemiaState)
  const translate = useTranslate()
  const navigation = useNavigation<NavigationTypes>()

  const moddedDrinks = addedDrinks.slice(0).reverse().map(drink => ({
    ...drink,
    id: `${drink.id}${drink.time.timestamp}`,
    realId: drink.id,
  }))

  return (
    <AlcoholemiaBackground css={styles.container}>
      <>
        {moddedDrinks.length > 0 ? (
          <AlcoholemiaScrollView>
            <View style={styles.viewContainer}>
              <>
                <Text css={styles.title} variant="h2">
                  {translate('Listado de las últimas bebidas consumidas')}
                </Text>
                <FlatList
                  data={moddedDrinks}
                  maxW={'100%'}
                  renderItem={({ item }: { item: CurrentDrink }) => (
                    <AlcoholemiaBox
                      borderBottomWidth="1"
                      _dark={{
                        borderColor: 'gray.600',
                      }}
                      borderColor="coolGray.200"
                      marginBottom={15}
                      pl="4"
                      pr="5"
                      py="2"
                      key={item.time.timestamp}
                    >
                      <HStack space={3} justifyContent="space-between">
                        <VStack>
                          <Text css={styles.textMarca} variant="bodyBold">
                            {item.marca} {item.presentacion}
                          </Text>
                          <Text css={styles.text}>
                            {item.tipo} {item.quantity}ml
                          </Text>
                        </VStack>
                        <Spacer />
                        <Text css={styles.text} variant="small">
                          {format(Date.parse(item.time.timestamp), 'hh:mmaaa dd-LLL')}
                        </Text>
                      </HStack>
                    </AlcoholemiaBox>
                  )}
                  keyExtractor={item => item.id}
                />
              </>
            </View>
          </AlcoholemiaScrollView>
        ) : (
          <>
            <Text css={styles.textCenter} variant="h2">
              {translate('En esta sección encontrarás el historial de tus bebidas')}.
            </Text>
            <Text css={styles.homeDesc} variant="h2">
              {translate('Visita el Home y registra lo qué estás tomando')}.
            </Text>
          </>
        )}
        <AlcoholemiaButton
          title={translate('Ir a Home')}
          style={styles.button}
          onPress={() => navigation.navigate('Home')}
        />
      </>
    </AlcoholemiaBackground>
  )
}

export default AddedDrinks
