import React from 'react'
import { Divider, IDividerProps } from 'native-base'

/**
 *
 * ### Container component for NativeBase Divider.
 *
 */

const AlcoholemiaDivider = (props: IDividerProps) => <Divider {...props} />

export default AlcoholemiaDivider
