import { CurrentDrink } from '../types/reduxTypes'

export const getReducedScore = (drinks: CurrentDrink[], bacus: number, firstPartyDrinkTimestamp: string) => {
  const initialDate = new Date(firstPartyDrinkTimestamp)

  return bacus * drinks.reduce((acc, drink) => {
    const drinkTime = new Date(drink.time.timestamp)

    // If the date of consumption is less than the firstPartyDrinkTimestamp, it belongs to another event and is ignored.
    const mlXpormilajeAlcohol = drinkTime >= initialDate ? drink.quantity * drink.pormilajeAlcohol : 0

    return acc + mlXpormilajeAlcohol
  }, 0)
}