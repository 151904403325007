import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { clearStatusReducer } from '../reducers/statusReducers'
import { getListado } from './listadoSlice'
import { successLoginReducer, toggleShowWelcomeReducer } from '../reducers/userReducers'
import getApiAdress from '../../utils/apiConfig'
import { deviceLanguage } from '../../utils/platformUtils'
import { LoginPayloadTypes, RegisterPayloadTypes, UserState } from '../../types/reduxTypes'
import { ApiErrorResponse } from '../../types/apiResponseTypes'
import { setModalData } from './modalSlice'

const SLICE_NAME = 'user'

const userInt = {
  codigo: '',
  id: 0,
  value: '',
}

export const userInitState: UserState = {
  nombre: '',
  correo: '',
  usuario: '',
  usuarioId: 0,
  identificador: 0,
  estatura: 0,
  sexo: 0,
  peso: 0,
  formatoFecha: '',
  formatoHora: '',
  idioma: userInt,
  pais: userInt,
  unidadesMedida: '',
  zonaHoraria: userInt,
  bacus: 0,
  auth: {
    isAuthenticated: false,
    token: '',
  },
  status: 'idle',
  message: '',
  showWelcome: false,
}

export type ExtraReducerErrorPayload = { payload: { response: ApiErrorResponse } }

export const LOGIN_ACTION: string = 'AUTH_LOGIN'
export const REGISTER_ACTION: string = 'AUTH_REGISTER'

/** Login  */
export const createLogin = createAsyncThunk(
  LOGIN_ACTION,
  async (payload: LoginPayloadTypes, { rejectWithValue, dispatch }) => {
    try {
      const { email, password } = payload

      const data = new FormData()
      data.append('correo', email)
      data.append('password', password)

      const response = await axios({
        url: `${getApiAdress().alcoholemiaApi}login/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        data,
      })

      dispatch(successLogin(response.data))

      dispatch(
        getListado({
          idiomaId: response.data.usuario.idioma.id,
          token: response.data.usuario.auth.token,
        })
      )
      dispatch(
        setModalData({
          data: {
            title: 'Welcome Back',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
          modalName: 'alert',
        })
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

/** Register  */
export const createRegister = createAsyncThunk(
  REGISTER_ACTION,
  async (payload: RegisterPayloadTypes, { dispatch, rejectWithValue }) => {
    try {
      const { email, birthday, gender, height, weight } = payload

      const data = new FormData()
      data.append('email', email)
      data.append('birthday', birthday)
      data.append('gender', gender)
      data.append('height', height)
      data.append('weight', weight)
      data.append('deviceLanguage', deviceLanguage)

      const response = await axios({
        url: `${getApiAdress().alcoholemiaApi}register/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        data,
      })

      dispatch(successLogin({ ...response.data, showWelcome: true }))

      dispatch(
        getListado({
          idiomaId: response.data.usuario.idioma.id,
          token: response.data.usuario.auth.token,
        })
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const UserSlice = createSlice({
  initialState: userInitState,
  name: SLICE_NAME,
  reducers: {
    successLogin: successLoginReducer,
    clearUserStatus: clearStatusReducer,
    toggleShowWelcome: toggleShowWelcomeReducer,
  },
  extraReducers: {
    /** Login  */
    [`${createLogin.pending}`]: state => {
      state.status = 'loading'
      state.message = ''
    },
    [`${createLogin.fulfilled}`]: state => {
      state.status = 'success'
      state.message = ''
    },
    [`${createLogin.rejected}`]: (state, { payload }: ExtraReducerErrorPayload) => {
      state.status = 'error'
      state.message = payload.response ? payload.response.data.description : 'Error'
    },
    //   /** Register */
    [`${createRegister.pending}`]: state => {
      state.status = 'loading'
      state.message = ''
    },
    [`${createRegister.fulfilled}`]: state => {
      state.status = 'success'
      state.message = ''
    },
    [`${createRegister.rejected}`]: (state, { payload }: ExtraReducerErrorPayload) => {
      state.status = 'error'
      state.message = payload.response ? payload.response.data.description : 'Error'
    },
  },
})

export const { successLogin, clearUserStatus, toggleShowWelcome } = UserSlice.actions

export const getUserState = ({ user }: { user: UserState }) => user

export default UserSlice.reducer
