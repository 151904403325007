import { IScrollViewProps, ScrollView } from 'native-base'
import React from 'react'
import { StyleSheet, TextStyle } from 'react-native'

interface AlcoholemiaScrollViewProps extends IScrollViewProps {
  children: React.ReactNode
  css?: TextStyle
}

const styles = StyleSheet.create({
  container: { alignItems: 'center', justifyContent: 'center' },
})

const AlcoholemiaScrollView = ({ children, css = {} }: AlcoholemiaScrollViewProps) => (
  <ScrollView contentContainerStyle={[styles.container, css]}>{children}</ScrollView>
)

export default AlcoholemiaScrollView
