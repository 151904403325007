import React from 'react'
import Text from './Text'

/**
 * ### This component is only to avoid to show the back button from the React-navigation header
 */

const Null = () => <Text variant="body">{''}</Text>

export default Null
