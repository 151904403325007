import { createSlice } from '@reduxjs/toolkit'

import { setModalDataReducer, toggleModalReducer } from '../reducers/modalReducers'

import { ModalStatus } from '../../types/reduxTypes'

const SLICE_NAME = 'modal'

export const modalInitState: ModalStatus = {
  viewDrink: {
    data: {
      id: 0,
      imagen: [],
      marca: '',
      ml: 0,
      pormilajeAlcohol: 0,
      presentacion: '',
      tipo: '',
      variante: '',
      quantity: 0,
    },
    isOpen: false,
  },
  alert: {
    data: {
      description: '',
      title: '',
    },
    isOpen: false,
  },
}

const ModalSlice = createSlice({
  name: SLICE_NAME,
  initialState: modalInitState,
  reducers: {
    setModalData: setModalDataReducer,
    toggleModal: toggleModalReducer,
  },
})

export const { setModalData, toggleModal } = ModalSlice.actions

export const getModalState = ({ modal }: { modal: ModalStatus }) => modal

export default ModalSlice.reducer
