import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import getApiAdress from '../../utils/apiConfig'
import { clearStatusReducer } from '../reducers/statusReducers'

import { ListadoApiResponse, ApiErrorResponse } from '../../types/apiResponseTypes'
import { GetListadoPayloadTypes, ListadoState } from '../../types/reduxTypes'

const SLICE_NAME = 'listado'
export const listadoInitState: ListadoState = {
  listado: [],
  hits: 0,
  status: 'idle',
  message: '',
}

type ExtraReducerGetListadoFulfilled = { payload: ListadoApiResponse }
type ExtraReducerGetListadoRejectedPayload = { payload: { response: ApiErrorResponse } }

export const LISTADO_GET_ACTION: string = 'LISTADO_GET'

export const getListado = createAsyncThunk(
  LISTADO_GET_ACTION,
  async ({ idiomaId, token }: GetListadoPayloadTypes, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${getApiAdress().alcoholemiaApi}listado/query/?idioma_id=${idiomaId}`,
        headers: {
          'content-Type': 'application/json',
          ['Authorization']: `token ${token}`,
        },
        withCredentials: true,
      })

      return fulfillWithValue(response.data)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const ListadoSlice = createSlice({
  name: SLICE_NAME,
  initialState: listadoInitState,
  reducers: {
    clearListadoStatus: clearStatusReducer,
  },
  extraReducers: {
    [`${getListado.pending}`]: state => {
      state.status = 'loading'
      state.message = ''
    },
    [`${getListado.fulfilled}`]: (state, { payload }: ExtraReducerGetListadoFulfilled) => {
      state.hits = payload.hits
      state.listado = payload.listado
      state.status = 'success'
      state.message = ''
    },
    [`${getListado.rejected}`]: (state, { payload }: ExtraReducerGetListadoRejectedPayload) => {
      state.status = 'error'
      state.hits = 0
      state.message = payload.response.data.description
    },
  },
})

export const { clearListadoStatus } = ListadoSlice.actions

export const getListadoState = ({ listado }: { listado: ListadoState }) => listado

export default ListadoSlice.reducer
