import { Drink } from '../types/apiResponseTypes'

export const getRelatedDrinks = (listado: Drink[], tipo: string, marca: string, variante: string) =>
  listado.filter(
    drink => drink.tipo === tipo && drink.marca === marca && drink.variante === variante && drink
  )

export const getSortedDrinkOptions = (drinks: Drink[]) =>
  drinks
    .sort((a, b) => {
      if (a.id < b.id) {
        return 1
      }
      if (a.id > b.id) {
        return -1
      }
      return 0
    })
    .map(drink => ({
      label: drink.presentacion,
      value: drink.id,
    }))
