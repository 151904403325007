import AsyncStorage from '@react-native-async-storage/async-storage'
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'

import alcoholemia, { alcoholemiaInitState } from './slices/alcoholemiaSlice'
import busqueda, { busquedaInitState } from './slices/busquedaSlice'
import user, { userInitState } from './slices/userSlice'
import listado, { listadoInitState } from './slices/listadoSlice'
import modal, { modalInitState } from './slices/modalSlice'
import { isProduction } from '../utils/platformUtils'

import { RootState } from '../types/reduxTypes'

const combinedReducer = combineReducers<RootState>({
  alcoholemia,
  busqueda,
  listado,
  modal,
  user,
})

const storeInitState: RootState = {
  alcoholemia: alcoholemiaInitState,
  busqueda: busquedaInitState,
  listado: listadoInitState,
  modal: modalInitState,
  user: userInitState,
}

const rootReducer = (state: any, action: { type: string }) => {
  if (action.type === 'LOGOUT') {
    return (state = storeInitState)
  }

  return combinedReducer(state, action)
}

const reducer = persistReducer(
  {
    key: 'root',
    storage: AsyncStorage,
  },
  rootReducer
)

const store = configureStore({
  reducer,
  devTools: !isProduction,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store

export const persistor = persistStore(store)
