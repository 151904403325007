import { Badge, IBadgeProps } from 'native-base'
import React from 'react'

interface Props extends IBadgeProps {
  children: React.ReactChild
}

const AlcoholemiaBadge = ({ children, ...props }: Props) => {
  return <Badge {...props}>{children}</Badge>
}

export default AlcoholemiaBadge
