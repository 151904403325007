import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  AddDrinkPayloadProps,
  addDrinkReducer,
  setAlcoholemiaReducer,
} from '../reducers/alcoholemiaReducers'

import getApiAdress from '../../utils/apiConfig'

import { AlcoholemiaState, RootState } from '../../types/reduxTypes'

const SLICE_NAME = 'alcoholemia'

export const alcoholemiaInitState: AlcoholemiaState = {
  score: 0,
  firstPartyDrinkTimestamp: '',
  currentDrink: {
    id: 0,
    imagen: [],
    marca: '',
    ml: 0,
    pormilajeAlcohol: 0,
    presentacion: '',
    tipo: '',
    variante: '',
    time: {
      timestamp: '',
      timezone: 0,
    },
    quantity: 0,
  },
  addedDrinks: [],
  status: 'idle',
  message: '',
}

const SAVE_DRINK_ACTION_NAME = 'ALCOHOLEMIA_SAVE_DRINK'

export const saveDrink = createAsyncThunk(
  SAVE_DRINK_ACTION_NAME,
  async (payload: AddDrinkPayloadProps, { dispatch, getState }) => {
    try {
      const { user } = <RootState>getState()
      const data = [
        {
          id: payload.currentDrink.id,
          quantity: payload.currentDrink.quantity,
          timestamp: new Date(payload.currentDrink.time.timestamp),
        },
      ]

      const response = await axios({
        url: `${getApiAdress().alcoholemiaApi}addDrink/?idioma_id=${user.idioma.id}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ['Authorization']: `token ${user.auth.token}`,
        },
        withCredentials: true,
        data,
      })
      const addedDrinkId = response.data[0]

      dispatch(addDrink({ currentDrink: { ...payload.currentDrink, addedDrinkId } }))
    } catch (error) {
      dispatch(addDrink({ currentDrink: { ...payload.currentDrink, addedDrinkId: 0 } }))
    }
  }
)

const AlcoholemiaSlice = createSlice({
  name: SLICE_NAME,
  initialState: alcoholemiaInitState,
  reducers: {
    addDrink: addDrinkReducer,
    setAlcoholemia: setAlcoholemiaReducer,
  },
})

export const { setAlcoholemia, addDrink } = AlcoholemiaSlice.actions

export const getAlcoholemiaState = ({ alcoholemia }: { alcoholemia: AlcoholemiaState }) =>
  alcoholemia

export default AlcoholemiaSlice.reducer
