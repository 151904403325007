import React from 'react'
import { IImageProps, Image } from 'native-base'

/**
 *
 * ### Container component for NativeBase Image.
 *
 */

const AlcoholemiaImage = (props: IImageProps) => {
  return <Image {...props} />
}

export default AlcoholemiaImage
