import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import Login from '../screens/Login'
import Register from '../screens/Register'

import GoBackHeader from '../atoms/GoBackHeader'

import { useTranslate } from '../../hooks'
import { isAndroid, isWeb } from '../../utils/platformUtils'

import { RootStackParamList } from '../../types/ScreenTypes'

const NativeStack = createNativeStackNavigator<RootStackParamList>()

/**
 * This component renders public screens:
 *
 * @public Login
 * @public Register
 */

const PublicScreens = () => {
  const translate = useTranslate()

  return (
    <NativeStack.Navigator>
      <NativeStack.Screen
        name="Login"
        component={Login}
        options={{
          title: 'Alcoholemia',
          headerBackTitleVisible: false,
          headerTitle: '',
          headerTintColor: 'white',
          animation: isAndroid ? 'slide_from_left' : 'slide_from_right',
        }}
      />
      <NativeStack.Screen
        name="Register"
        component={Register}
        options={{
          title: translate('Registro'),
          headerTitleAlign: 'center',
          animation: isAndroid ? 'slide_from_right' : 'slide_from_left',
          headerTintColor: 'white',
          headerLeft: () => (isWeb ? <GoBackHeader /> : null),
        }}
      />
    </NativeStack.Navigator>
  )
}

export default PublicScreens
