import React from 'react'
import { StyleSheet } from 'react-native'
import { useDispatch } from 'react-redux'

import AlcoholemiaBackground from '../atoms/AlcoholemiaBackground'
import AlcoholemiaButton from '../atoms/AlcoholemiaButton'
import Text from '../atoms/Text'

import { toggleShowWelcome } from '../../redux/slices/userSlice'
import { useTranslate } from '../../hooks'

const styles = StyleSheet.create({
  header: {
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 15,
    textShadowColor: 'rgba(255, 255, 255, 0.45)',
    textAlign: 'center',
    marginBottom: 10,
    color: '#2d99cc',
  },
  body: {
    textAlign: 'justify',
  },
  button: {
    marginTop: 15,
  },
})

/**
 * ### Renders the Welcome Screen
 */

const Welcome: React.FC = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()

  return (
    <AlcoholemiaBackground>
      <>
        <Text variant="h3" css={styles.header}>
          {translate('Bienvenido')}
        </Text>
        <Text variant="body">
           {translate('Tu cuenta ha sido creada con éxito')}{'. '}
           {translate('Se envió una contraseña a tu correo electrónico con tu contraseña temporal (verifica la carpeta de spam)')}{'.'}
        </Text>
        <Text variant="body">
           {translate('Recuerda que los resultados que se muestran son aproximaciones')}{'. '}
           {translate('Bebe con resposabilidad')}{'.'}
        </Text>
        <AlcoholemiaButton
          onPress={() => dispatch(toggleShowWelcome())}
          style={styles.button}
          title={translate('Ir a Home')}
        />
      </>
    </AlcoholemiaBackground>
  )
}

export default Welcome
