import React, { useEffect, useRef } from 'react'
import { View } from 'native-base'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import Text from './Text'

import { getAlcoholemiaState, setAlcoholemia } from '../../redux/slices/alcoholemiaSlice'
import { getUserState } from '../../redux/slices/userSlice'

const styles = StyleSheet.create({
  score: {
    fontSize: 80,
    paddingTop: 60,
    width: '100%',
    textAlign: 'center',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 15,
    textShadowColor: 'rgba(255, 255, 255, 0.45)',
  },
})

const Score = () => {
  const dispatch = useDispatch()
  const alcoholemiaInterval = useRef<any>()
  const { addedDrinks, score } = useSelector(getAlcoholemiaState)
  const { bacus } = useSelector(getUserState)

  useEffect(() => {
    dispatch(setAlcoholemia(bacus))
    if (alcoholemiaInterval.current) {
      clearInterval(alcoholemiaInterval.current)
    }
    alcoholemiaInterval.current = setInterval(() => dispatch(setAlcoholemia(bacus)), 24000)

    return () => {
      clearInterval(alcoholemiaInterval.current)
    }
  }, [addedDrinks.length])

  return (
    <View>
      <Text variant="bodyOrbitronBold" css={styles.score}>
        {score}
      </Text>
    </View>
  )
}

export default Score
