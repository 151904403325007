import React from 'react'
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
} from '@react-navigation/drawer'
import { useDispatch, useSelector } from 'react-redux'

import AddedDrinks from './AddedDrinks'
import Home from './Home'
import Welcome from './Welcome'
import Logo from '../atoms/Logo'

import { getUserState } from '../../redux/slices/userSlice'
import { isDevelopment } from '../../utils/platformUtils'
import { useTranslate } from '../../hooks'

const Drawer = createDrawerNavigator()

const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />
      {isDevelopment && <DrawerItem label={translate('Cerrar sesión')} onPress={() => dispatch({ type: 'LOGOUT' })} />}
    </DrawerContentScrollView>
  )
}

const Sidebar: React.FC = () => {
  const { showWelcome } = useSelector(getUserState)
  const translate = useTranslate()
  return (
    <Drawer.Navigator
      screenOptions={{
        headerTintColor: 'white',
        drawerActiveTintColor: 'white',
        drawerContentStyle: { backgroundColor: '#34aacc' },
      }}
      drawerContent={props => <CustomDrawerContent {...props} />}
    >
      <Drawer.Screen
        name="Home"
        options={{
          title: 'Alcoholemia.mx',
          headerTitle: showWelcome ? 'Welcome' : Logo,
          headerTitleAlign: 'center',
        }}
        component={showWelcome ? Welcome : Home}
      />
      <Drawer.Screen
        name="AddedDrinks"
        options={{
          title: `${translate('Bebidas agregadas')} - Alcoholemia.mx`,
          headerTitle: translate('Bebidas agregadas'),
          headerTitleAlign: 'center',
        }}
        component={AddedDrinks}
      />
    </Drawer.Navigator>
  )
}

export default Sidebar
