import React from 'react'
import Modal from 'react-native-modal'
import { ScrollView } from 'react-native-gesture-handler'
import { useDispatch, useSelector } from 'react-redux'
import { StyleSheet, View } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'

import AlcoholemiaButton from '../atoms/AlcoholemiaButton'
import Text from '../atoms/Text'
import ViewDrinksForm from './ViewDrinksForm'

import { getListadoState } from '../../redux/slices/listadoSlice'
import { getModalState, toggleModal } from '../../redux/slices/modalSlice'
import { isIOS } from '../../utils/platformUtils'

const styles = StyleSheet.create({
  modalView: {
    marginTop: isIOS ? 50 : 20,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: 'white',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 15,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalContainer: {
    flex: 1,
  },
  text: {
    color: 'black',
    alignSelf: 'center',
  },
  scrollViewContainer: { flexGrow: 1 },
})

const ViewDrinksModal = ({ showScore }: { showScore: (flag: boolean) => void }) => {
  const {
    viewDrink: { isOpen, data },
  } = useSelector(getModalState)
  const { listado } = useSelector(getListadoState)
  const { marca, tipo, variante, pormilajeAlcohol } = data
  const dispatch = useDispatch()

  return (
    <>
      <Modal
        style={styles.modalView}
        isVisible={isOpen}
        onSwipeComplete={() => dispatch(toggleModal('viewDrink'))}
        swipeDirection="down"
      >
        <AlcoholemiaButton
          alignSelf={'flex-start'}
          variant={'link'}
          onPress={() => dispatch(toggleModal('viewDrink'))}
          title={<MaterialIcons size={20} name="close" />}
        />
        <ScrollView
          contentContainerStyle={styles.scrollViewContainer}
          keyboardShouldPersistTaps="handled"
        >
          <View style={styles.modalContainer}>
            <Text css={styles.text}>{tipo}</Text>
            <Text css={styles.text}>
              {marca} {variante}
            </Text>
            <Text css={styles.text}>{(pormilajeAlcohol * 100).toFixed(1)}%</Text>

            <ViewDrinksForm showScore={showScore} listado={listado} modalData={data} />
          </View>
        </ScrollView>
      </Modal>
    </>
  )
}

export default ViewDrinksModal
