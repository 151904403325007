import React from 'react'
import { useForm } from 'react-hook-form'
import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import AlcoholemiaButton from '../atoms/AlcoholemiaButton'
import AlcoholemiaInput from '../atoms/AlcoholemiaInput'

import { useTranslate } from '../../hooks'
import { isDevelopment } from '../../utils/platformUtils'
import { createLogin } from '../../redux/slices/userSlice'

const styles = StyleSheet.create({
  loginFormTextInput: {
    width: '100%',
    marginBottom: 10,
    backgroundColor: 'white',
  },
  submitButton: {
    width: 190,
    alignSelf: 'center',
    marginBottom: 10,
    marginTop: 10,
  },
  inputLabel: {
    textAlign: 'center',
  },
})

type LoginArgs = { email: string; password: string }

/**
 * ### Renders the Login form
 */

const LoginForm: React.FC = () => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const form = useForm({
    defaultValues: {
      email: isDevelopment ? 'floresfuentes93@gmail.com' : '',
      password: isDevelopment ? 'test2' : '',
    },
  })
  const { handleSubmit } = form

  const handleLogin = ({ email, password }: LoginArgs) => dispatch(createLogin({ email, password }))

  return (
    <View>
      <AlcoholemiaInput
        form={form}
        name="email"
        required={true}
        css={styles.loginFormTextInput}
        label={translate('Correo')}
        labelCSS={styles.inputLabel}
        type="email"
        textContentType="emailAddress"
        keyboardType="email-address"
        variant="filled"
      />
      <AlcoholemiaInput
        form={form}
        name="password"
        required={true}
        css={styles.loginFormTextInput}
        label={translate('Contraseña')}
        labelCSS={styles.inputLabel}
        type="password"
        textContentType="password"
        secureTextEntry={true}
        variant="filled"
      />
      <AlcoholemiaButton
        style={styles.submitButton}
        title={`${translate('Iniciar sesión')}`}
        onPress={handleSubmit(handleLogin)}
      />
    </View>
  )
}

export default LoginForm
