import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { format, addMinutes } from 'date-fns'

import Text from '../atoms/Text'
import Clock from '../atoms/Clock'

import { getAlcoholemiaState } from '../../redux/slices/alcoholemiaSlice'
import { useTranslate } from '../../hooks'

const styles = StyleSheet.create({
  main: { textAlign: 'center', marginBottom: 5 },
})

/**
 * @returns A real time digital clock with hour, minutes and seconds
 * @returns When will you be sober
 * @returns when will you have 8 points
 */

const Timing = () => {
  const translate = useTranslate()
  const [sober, setSober] = useState('')
  const [eightAt, setEightAt] = useState('')
  const { score } = useSelector(getAlcoholemiaState)

  useEffect(() => {
    const currentDate = new Date()

    setSober(format(addMinutes(currentDate, score * 40), 'hh:mm a'))
    setEightAt(format(addMinutes(currentDate, (score - 8) * 40), 'hh:mm a'))
  }, [score])

  return (
    <View>
      <Clock />
      {score > 0 && (
        <Text variant="body" css={styles.main}>
          {translate('Sobrio a las')} <Text variant="bodyOrbitron">{sober}</Text>
        </Text>
      )}
      {score > 8 && (
        <Text variant="body" css={styles.main}>
          {translate('8 puntos a las')} <Text variant="bodyOrbitron">{eightAt}</Text>
        </Text>
      )}
    </View>
  )
}

export default Timing
