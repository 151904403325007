import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Keyboard, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { MaterialIcons } from '@expo/vector-icons'
import { IInputProps as InputPropsNativeBase } from 'native-base'

import AlcoholemiaBox from '../atoms/AlcoholemiaBox'
import AlcoholemiaButton from '../atoms/AlcoholemiaButton'
import AlcoholemiaInput from '../atoms/AlcoholemiaInput'
import Text from '../atoms/Text'
import SearchResults from './SearchResults'
import LastDrinks from './LastDrinks'

import { saveSearchTerm } from '../../redux/slices/busquedaSlice'
import { getUserState } from '../../redux/slices/userSlice'
import { getListadoState } from '../../redux/slices/listadoSlice'
import { useTranslate } from '../../hooks'

import { Drink } from '../../types/apiResponseTypes'

interface LiveSearchProps extends InputPropsNativeBase {
  shouldShowScore: (showScore: boolean) => void
  scoreState: boolean
}

const styles = StyleSheet.create({
  LastDrinksBox: {
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
  resultsBox: {
    borderTopEndRadius: 0,
    borderTopStartRadius: 0,
    borderWidth: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    paddingRight: 5,
  },
  liveSearchInput: {
    width: '100%',
    marginTop: 10,
    backgroundColor: 'white',
  },
  text: {
    color: 'grey',
  },
  liveSearchDesc: {
    marginBottom: 10,
    alignSelf: 'center',
  },
})

/**
 * ### Renders a Live search input
 * @param shouldShowScore `Function to show or hide the score elements`[FUNCTION][REQUIRED]
 */

const LiveSearch = ({ scoreState, shouldShowScore, ...liveSearchProps }: LiveSearchProps) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const { listado } = useSelector(getListadoState)
  const {
    auth: { token },
  } = useSelector(getUserState)
  const [drinksFiltered, setDrinksFiltered] = useState<Drink[]>([])
  const form = useForm({
    defaultValues: {
      liveSearchValue: '',
    },
  })
  const { liveSearchValue } = form.watch()

  const filteredDrinks = useMemo(() => {
    const filter = listado.filter(({ marca, variante, tipo }) => {
      const keywords = liveSearchValue.toLocaleLowerCase().split(' ')

      const keywordExists = keywords.map(keyword =>
        `${marca} ${variante} ${tipo}`.toLocaleLowerCase().includes(keyword)
      )

      return keywordExists.every(keyword => keyword)
    })

    const distincts = [
      ...new Map(
        filter.map(item => [item.marca, item] && [item.tipo, item] && [item.variante, item])
      ).values(),
    ]
    setDrinksFiltered(distincts)
  }, [liveSearchValue, listado])

  useEffect(() => {
    liveSearchValue.length === 0 && setDrinksFiltered([])
    liveSearchValue.length > 1 && listado.length > 0 && filteredDrinks
    scoreState && form.setValue('liveSearchValue', '')

    const typingTimeout = setTimeout(
      () =>
        liveSearchValue.length > 1 && dispatch(saveSearchTerm({ term: liveSearchValue, token })),
      300
    )

    return () => clearTimeout(typingTimeout)
  }, [liveSearchValue, scoreState])

  return (
    <>
      {!scoreState && (
        <View>
          <AlcoholemiaButton
            alignSelf={'flex-start'}
            variant={'link'}
            position={'absolute'}
            bottom={1}
            onPress={() => {
              shouldShowScore(true)
              Keyboard.dismiss()
            }}
            title={<MaterialIcons color={'white'} size={20} name="close" />}
          />
          <Text css={styles.liveSearchDesc} variant="h5">
            {translate('Busca lo que estás tomando')}
          </Text>
        </View>
      )}
      <AlcoholemiaInput
        form={form}
        name="liveSearchValue"
        onFocus={() => shouldShowScore(false)}
        onBlur={() => {
          setTimeout(() => {
            liveSearchValue.length === 0 && shouldShowScore(true)
          }, 200)
        }}
        variant="filled"
        placeholder={translate('¿Qué estás tomando?')}
        css={styles.liveSearchInput}
        // InputLeftElement={
        //   <Icon ml="2" size="5" color="black" as={<MaterialIcons name="search" />} />
        // }
        {...liveSearchProps}
      />

      {!scoreState && (
        <View>
          {liveSearchValue.length < 2 && (
            <AlcoholemiaBox style={styles.LastDrinksBox}>
              <LastDrinks />
            </AlcoholemiaBox>
          )}
          <AlcoholemiaBox style={styles.resultsBox}>
            <View>
              {liveSearchValue.length > 1 && drinksFiltered.length ? (
                <SearchResults drinks={drinksFiltered} />
              ) : (
                liveSearchValue.length > 1 && (
                  <Text css={styles.text} variant="body">
                    {translate('No se encontraron resultados para')}: {liveSearchValue}
                  </Text>
                )
              )}
            </View>
          </AlcoholemiaBox>
        </View>
      )}
    </>
  )
}

export default LiveSearch
