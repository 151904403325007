import React from 'react'
import { StyleSheet } from 'react-native'
import { Button, Modal } from 'native-base'
import { useDispatch, useSelector } from 'react-redux'

import AlcoholemiaButton from './AlcoholemiaButton'
import Text from './Text'

import { useTranslate } from '../../hooks'
import { getModalState, toggleModal } from '../../redux/slices/modalSlice'

const styles = StyleSheet.create({
  text: {
    color: 'black',
  },
})

const AlcoholemiaAlert = () => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const {
    alert: { data, isOpen },
  } = useSelector(getModalState)
  return (
    <Modal isOpen={isOpen} onClose={() => dispatch(toggleModal('alert'))}>
      <Modal.Content maxWidth="350" maxH="212">
        <Modal.CloseButton />
        <Modal.Header>{data.title}</Modal.Header>
        <Modal.Body>
          <Text css={styles.text}>{data.description}</Text>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <AlcoholemiaButton
              title={translate('Entendido')}
              onPress={() => dispatch(toggleModal('alert'))}
            />
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

export default AlcoholemiaAlert
