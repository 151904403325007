import { ModalData, ModalNames, ModalStatus } from '../../types/reduxTypes'

type SetModalPayload = {
  payload: {
    data:
      | ModalData
      | {
          title: string
          description: string
        }
    modalName: ModalNames
  }
}
type toggleModalPayload = {
  payload: ModalNames
}

export const toggleModalReducer = function (state: ModalStatus, { payload }: toggleModalPayload) {
  state[payload].isOpen = !state[payload].isOpen
}

export const setModalDataReducer = function (state: ModalStatus, { payload }: SetModalPayload) {
  state[payload.modalName].data = payload.data
  state[payload.modalName].isOpen = true
}
