import React from 'react'
import { StyleSheet } from 'react-native'

import AlcoholemiaImage from './AlcoholemiaImage'

const styles = StyleSheet.create({
  logo: {
    height: 40,
    width: 40,
  },
})

const Logo = () => {
  const logo = require('../../../assets/logo.png')
  return (
    <>
      <AlcoholemiaImage resizeMode="center" alt="Alcoholemia" style={styles.logo} source={logo} />
    </>
  )
}

export default Logo
