import React, { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useNavigation } from '@react-navigation/native'

import AlcoholemiaBackground from '../atoms/AlcoholemiaBackground'
import Text from '../atoms/Text'
import Timing from '../molecules/Timing'
import LiveSearch from '../molecules/LiveSearch'
import Score from '../atoms/Score'
import ViewDrinksModal from '../organisms/ViewDrinksModal'

import { getListadoState } from '../../redux/slices/listadoSlice'
import { useToast, useTranslate } from '../../hooks'
import { isWeb } from '../../utils/platformUtils'

import { NavigationTypes } from '../../types/ScreenTypes'

const styles = StyleSheet.create({
  container: { justifyContent: 'flex-start' },
  scoreText: {
    fontSize: 16,
    marginTop: isWeb ? 62 : 24,
    transform: [{ rotate: '-90deg' }],
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 15,
    textShadowColor: 'rgba(255, 255, 255, 0.45)',
  },
  inputContainer: { width: '100%', marginTop: 0 },
  headerContainer: {
    flexDirection: 'row',
    marginTop: isWeb ? -30 : 0,
  },
  timingContainer: {
    marginTop: isWeb ? 40 : 0,
  },
  scrollViewContainer: { flexGrow: 1 },
})

/**
 * @returns The Home screen
 */

const Home: React.FC = () => {
  const [showScore, setShowScore] = useState(true)
  const { message, status } = useSelector(getListadoState)
  const toast = useToast('listado')
  const translate = useTranslate()
  const navigation = useNavigation<NavigationTypes>()

  useEffect(() => {
    toast(status, message)
  }, [status])

  return (
    <ScrollView
      contentContainerStyle={styles.scrollViewContainer}
      keyboardShouldPersistTaps="handled"
    >
      <AlcoholemiaBackground css={styles.container}>
        <>
          {showScore ? (
            <>
              <TouchableOpacity
                onPress={() => navigation.navigate('AddedDrinks')}
                style={styles.headerContainer}
              >
                <Score />
                <View>
                  <Text variant="bodyOrbitronBold" css={styles.scoreText}>
                    {translate('puntos')}
                  </Text>
                </View>
              </TouchableOpacity>

              <View style={styles.timingContainer}>
                <Timing />
              </View>
            </>
          ) : null}

          <View style={styles.inputContainer}>
            <LiveSearch scoreState={showScore} shouldShowScore={setShowScore} />
          </View>
        </>
      </AlcoholemiaBackground>
      <ViewDrinksModal showScore={setShowScore} />
    </ScrollView>
  )
}

export default Home
