import React from 'react'
import { ImageBackground, StyleSheet, TextStyle } from 'react-native'

import { isWeb } from '../../utils/platformUtils'

interface BackgroundProps {
  children: React.ReactChild
  css?: TextStyle
}

const styles = StyleSheet.create({
  imageWrapper: {
    flex: 1,
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

/**
 * ### This component is a wrapper for each screen in the app, must be at the top level of each screen.
 * #### `Important!` This component doesn't supports not nested elements, you should wrap the child elements inside of a fragmen or View compoent.
 *
 * @param css Used to rewrite the base styles with custom styles[OBJECT]
 *
 */

const AlcoholemiaBackground: React.FC<BackgroundProps> = ({
  children,
  css = {},
}: BackgroundProps) => {
  const backgroundImage = require('../../../assets/loginBackground.jpeg')

  return (
    <ImageBackground
      source={backgroundImage}
      resizeMode={!isWeb ? 'cover' : 'stretch'}
      style={[styles.imageWrapper, css]}
    >
      {children}
    </ImageBackground>
  )
}

export default AlcoholemiaBackground
