import { isProduction } from './platformUtils'

type GetApiAdressTypes = () => {
  isProduction: boolean
  env: string | undefined
  alcoholemiaApi: string
  alcoholemiaImg: string
  alcoholemiaWeb: string
}

export const getApiAdress: GetApiAdressTypes = () => {
  switch (process.env.NODE_ENV) {
    case 'production': {
      return {
        isProduction,
        env: process.env.NODE_ENV,
        alcoholemiaApi: 'https://api.alcoholemia.mx/',
        alcoholemiaImg: 'https://img.alcoholemia.mx/',
        alcoholemiaWeb: 'https://alcoholemia.mx/',
      }
    }
    case 'development':
      return {
        isProduction: false,
        env: process.env.NODE_ENV,
        // alcoholemiaApi: 'http://api.alcoholemia.localhost/',
        alcoholemiaApi: 'http://192.168.0.180/',
        alcoholemiaImg: 'http://img.alcoholemia.localhost/',
        alcoholemiaWeb: 'http://localhost:19006/',
      }
    default: {
      return {
        isProduction,
        env: process.env.NODE_ENV,
        alcoholemiaApi: 'https://api.qa.alcoholemia.mx/',
        alcoholemiaImg: 'https://img.qa.alcoholemia.mx/',
        alcoholemiaWeb: 'https://qa.alcoholemia.mx/',
      }
    }
  }
}

export default getApiAdress
