import { BusquedaStatus, BusquedaTerm } from '../../types/reduxTypes'

export const addSearchReducer = function (
  state: BusquedaStatus,
  { payload }: { payload: BusquedaTerm[] }
) {
  state.history = payload
}

export const clearSearchArrayReducer = function (state: BusquedaStatus) {
  state.history = []
}
