import React from 'react'
import { StyleSheet, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useDispatch } from 'react-redux'
import { HStack, Spacer, VStack } from 'native-base'

import Text from '../atoms/Text'

import { setModalData } from '../../redux/slices/modalSlice'

import { Drink } from '../../types/apiResponseTypes'
import { ModalData } from '../../types/reduxTypes'

interface SearchResultsProps {
  drinks: Drink[]
}

const styles = StyleSheet.create({
  text: {
    color: 'grey',
  },
  textMarca: {
    color: '#4a4a4a',
  },
  resultContainer: {
    paddingBottom: 10,
    paddingTop: 10,
    borderBottomColor: '#eee',
    borderBottomWidth: 2,
  },
})

const SearchResults = ({ drinks }: SearchResultsProps) => {
  const dispatch = useDispatch()

  const setModal = (data: ModalData) => dispatch(setModalData({ data, modalName: 'viewDrink' }))

  return (
    <View>
      {drinks.map(drink => (
        <View style={styles.resultContainer} key={drink.id}>
          <TouchableOpacity onPress={() => setModal(drink)}>
            <HStack space={3} justifyContent="space-between">
              <VStack>
                <Text css={styles.textMarca} variant="bodyBold">
                  {drink.marca} {drink.variante}
                </Text>
                <Text css={styles.text}>{drink.tipo}</Text>
              </VStack>
              <Spacer />
              <Text css={styles.text} variant="small">
                {(drink.pormilajeAlcohol * 100).toFixed(1)}%
              </Text>
            </HStack>
          </TouchableOpacity>
        </View>
      ))}
    </View>
  )
}

export default SearchResults
