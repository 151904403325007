import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Keyboard, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import AlcoholemiaButton from '../atoms/AlcoholemiaButton'
import AlcoholemiaInput from '../atoms/AlcoholemiaInput'
import AlcoholemiaSelect from '../atoms/AlcoholemiaSelect'
import Text from '../atoms/Text'

import { saveDrink } from '../../redux/slices/alcoholemiaSlice'
import { setModalData, toggleModal } from '../../redux/slices/modalSlice'
import { useTranslate } from '../../hooks'
import { getRelatedDrinks, getSortedDrinkOptions } from '../../utils/listadoUtils'

import { AddDrinkPayloadProps } from '../../redux/reducers/alcoholemiaReducers'
import { Drink } from '../../types/apiResponseTypes'
import { ModalData } from '../../types/reduxTypes'
import { getUserState } from '../../redux/slices/userSlice'

const styles = StyleSheet.create({
  formContainer: {
    marginTop: 15,
  },
  label: {
    color: 'black',
  },
  input: {
    marginBottom: 15,
  },
})

interface ViewDrinksFormProps {
  listado: Drink[]
  modalData: ModalData
  showScore: (flag: boolean) => void
}

type SubmitData = { cantidadConsumida: string; drinkId: string }

const ViewDrinksForm: React.FC<ViewDrinksFormProps> = ({
  listado,
  modalData,
  showScore,
}: ViewDrinksFormProps) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const { bacus } = useSelector(getUserState)
  const { id, marca, tipo, variante, quantity } = modalData

  const form = useForm({
    defaultValues: {
      drinkId: id,
      cantidadConsumida: 0,
    },
  })
  const { handleSubmit, setValue, watch } = form
  const { drinkId, cantidadConsumida } = watch()

  const relatedDrinks = getRelatedDrinks(listado, tipo, marca, variante)
  const options = getSortedDrinkOptions(relatedDrinks)
  const selectedDrink = useMemo(
    () => relatedDrinks.find(drink => drink.id === +drinkId && drink),
    [drinkId]
  )

  useEffect(() => {
    id && setValue('drinkId', id)
  }, [id])

  useEffect(() => {
    if (+drinkId < 1) {
      setValue('cantidadConsumida', 0)
    } else {
      selectedDrink &&
        setValue(
          'cantidadConsumida',
          quantity && id === selectedDrink.id ? quantity : selectedDrink.ml
        )
    }
  }, [selectedDrink, drinkId])

  const submitSelectedDrink = ({ cantidadConsumida }: SubmitData) => {
    const timestamp = new Date()
    const timezone = timestamp.getTimezoneOffset() / -60

    const drink: AddDrinkPayloadProps = {
      currentDrink: {
        ...(selectedDrink && +drinkId !== 0 ? selectedDrink : modalData),
        time: {
          timestamp: '' + timestamp,
          timezone,
        },
        quantity: +cantidadConsumida,
      },
    }

    Keyboard.dismiss()
    dispatch(saveDrink(drink))
    dispatch(toggleModal('viewDrink'))
    showScore(true)
    dispatch(
      setModalData({
        data: {
          title: translate('Bebida añadida'),
          description: `${
            translate('La bebida ha sido registrada exitosamente')}. ${
            translate('Esta bebida te sumó')} ${((selectedDrink?.pormilajeAlcohol ?? 0) * +cantidadConsumida * bacus).toFixed(2)} ${
            translate('puntos')}.`,
        },
        modalName: 'alert',
      })
    )
  }

  return (
    <>
      {options.length > 0 && (
        <View style={styles.formContainer}>
          {options.length === 1 ? (
            <Text css={styles.label}>{options[0].label}</Text>
          ) : (
            <AlcoholemiaSelect
              label={`${translate('Cantidad')}:`}
              labelCSS={styles.label}
              style={styles.input}
              name="drinkId"
              itemColor="black"
              form={form}
              options={[...options, { label: 'Otro', value: 0 }]}
            />
          )}
          <AlcoholemiaInput
            keyboardType="number-pad"
            label={`${translate('Cantidad consumida')} (ml.):`}
            labelCSS={styles.label}
            form={form}
            width="100%"
            onBlur={() => Keyboard.dismiss()}
            css={styles.input}
            name="cantidadConsumida"
          />
          <AlcoholemiaButton
            disabled={cantidadConsumida > 0 ? false : true}
            title={translate('Registrar bebida')}
            onPress={handleSubmit(submitSelectedDrink)}
          />
        </View>
      )}
    </>
  )
}

export default ViewDrinksForm
