import { UserApiResponse } from '../../types/apiResponseTypes'
import { UserState } from '../../types/reduxTypes'

interface SuccessLoginPropsPayload extends UserApiResponse {
  showWelcome: boolean
}

type ExtraReducerPayload = { payload: SuccessLoginPropsPayload }

export const successLoginReducer = function (state: UserState, { payload }: ExtraReducerPayload) {
  const { usuario, showWelcome } = payload

  const newState: UserState = {
    ...state,
    ...usuario,
    showWelcome: showWelcome ? showWelcome : false,
  }

  return newState
}

export const toggleShowWelcomeReducer = (state: UserState) => {
  state.showWelcome = !state.showWelcome
}
