import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { format, getTime } from 'date-fns'

import Text from './Text'
import { formatClock } from '../../utils/timeUtils'
import { isWeb } from '../../utils/platformUtils'

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    paddingTop: isWeb ? 0 : 20,
    paddingBottom: isWeb ? 5 : 10,
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 15,
    textShadowColor: 'rgba(255, 255, 255, 0.45)',
  },
  clock: { marginBottom: 5, fontSize: 36 },
  ampm: { marginBottom: 5, fontSize: 16 },
})

const initState = { hours: '', minutes: '', seconds: '', ampm: '' }

/**
 *
 * @returns A real time digital clock with hours, minutes, seconds and am or pm text.
 */

const Clock = () => {
  const [clock, setClock] = useState(initState)
  const date = new Date()
  const time = format(getTime(date), 'pp')
  const { hours, minutes, ampm, seconds } = formatClock(time)

  const clockSetter = () => {
    const { hours, minutes, ampm, seconds } = formatClock(time)
    setClock({ hours, minutes, ampm, seconds })
  }

  useEffect(() => {
    const clock = setTimeout(() => {
      clockSetter()
    }, 1000)
    return () => {
      clearTimeout(clock)
      setClock(initState)
    }
  }, [time])

  return (
    <Text variant="bodyOrbitron" css={styles.container}>
      {clock.hours ? (
        <>
          <Text variant="bodyOrbitron" css={styles.clock}>{`${clock.hours}:${clock.minutes}`}</Text>
          <Text variant="bodyOrbitron" css={styles.ampm}>
            {`:${clock.seconds}`} {clock.ampm}
          </Text>
        </>
      ) : (
        <>
          <Text variant="bodyOrbitron" css={styles.clock}>{`${hours}:${minutes}`}</Text>
          <Text variant="bodyOrbitron" css={styles.ampm}>
            {`:${seconds}`} {ampm}
          </Text>
        </>
      )}
    </Text>
  )
}

export default Clock
