import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import Sidebar from '../screens/Sidebar'
import Welcome from '../screens/Welcome'

import Null from '../atoms/Null'

import { useTranslate } from '../../hooks'
import { isAndroid } from '../../utils/platformUtils'

import { RootStackParamList } from '../../types/ScreenTypes'

const NativeStack = createNativeStackNavigator<RootStackParamList>()

/**
 * This component renders private screens:
 *
 * @private Sidebar
 * @private Welcome
 */

const PrivateScreens = () => {
  const translate = useTranslate()

  return (
    <NativeStack.Navigator>
      <NativeStack.Screen
        name="Sidebar"
        component={Sidebar}
        options={{
          title: 'Alcoholemia - Home',
          headerTitle: '',
          animation: isAndroid ? 'slide_from_right' : 'slide_from_left',
          headerShown: false,
        }}
      />
      <NativeStack.Screen
        name="Welcome"
        component={Welcome}
        options={{
          title: translate('Bienvenido'),
          headerTitleAlign: 'center',
          headerTitle: '',
          animation: isAndroid ? 'slide_from_right' : 'slide_from_left',
          headerTintColor: 'white',
          headerLeft: () => <Null />,
        }}
      />
    </NativeStack.Navigator>
  )
}

export default PrivateScreens
