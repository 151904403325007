import { deviceLanguage } from '../utils/platformUtils'

type TranslationsType = {
  [lang: string]: {
    [phrase: string]: string
  }
}

const useTranslate = () => {
  if (deviceLanguage !== 'en' && deviceLanguage !== 'fr') {
    return (str: string) => str
  }

  return (str: string) => {
    const translations: TranslationsType = {
      en: {
        [str]: str,
        Usuario: 'User',
        'Correo electrónico': 'Email',
        Contraseña: 'Password',
        'Iniciar sesión': 'Log in',
        'Inicia sesión': 'Log in',
        'Inicia sesión e indica lo que estás tomando para obtener un resultado preciso y en tiempo real, sobre la cantidad de alcohol que hay en tu organismo':
          "Log in and register what you're drinking to get an accurate live result about the blood alcohol content in your organism",
        '¿Olvidaste tu contraseña?': 'Forgot your password?',
        '¿Aún no tienes cuenta?': "Don't have an account?",
        '¡Regístrate!': 'Register!',
        'Este campo es requerido': 'This is a required field',
        'Calcula tu nivel de alcohol en la sangre': 'Calculate your blood alcohol content',
        'en tiempo real': 'in real time',
        Correo: 'Email',
        Registro: 'Register',
        Registrate: 'Sign up',
        Volver: 'Go back',
        'Crea una cuenta y comienza a calcular': 'Create an account and calculate',
        'de inmediato': 'inmediately',
        Genero: 'Gender',
        Masculino: 'Male',
        Femenino: 'Female',
        'Fecha de nacimiento': 'Birthday',
        Dia: 'Day',
        Día: 'Day',
        Mes: 'Month',
        Año: 'Year',
        Altura: 'Height',
        Peso: 'Weight',
        'Al dar click en Crear cuenta, aceptas los': 'By clicking on Create account, you agree to our',
        'términos de Alcoholemia': 'Alcoholemia terms',
        'y que has leído nuestra': 'and that you have read our',
        'Política de privacidad': 'Privacy Policy',
        'incluido el': 'including our',
        'Uso de cookies': 'Cookie Use',
        '¿Ya tienes una cuenta?': 'Already have an account?',
        Bienvenido: 'Welcome',
        'Tu cuenta ha sido creada con éxito': 'Your account has been created successfully',
        'Se envió una contraseña a tu correo electrónico con tu contraseña temporal (verifica la carpeta de spam)': 'A password was sent to your email with your temporary password (check the spam folder)',
        'Recuerda que los resultados que se muestran son aproximaciones': 'Remember that the results shown are approximations',
        'Bebe con resposabilidad': 'Drink responsibly',
        'Cerrar sesión': 'Logout',
        puntos: 'points',
        '8 puntos a las': '8 points at',
        'Sobrio a las': 'Sober at',
        'No se encontraron resultados para': 'No results found',
        Cantidad: 'Quantity',
        'Cantidad consumida': 'Amount consumed',
        'Registrar bebida': 'Register drink',
        'Bebida añadida': 'Drink added',
        'La bebida ha sido registrada exitosamente': 'The drink has been successfully registered',
        'Esta bebida te sumó': 'This drink added you',
        Cerrar: 'Close',
        Entendido: 'Got it',
        'Últimas bebidas': 'Last drinks',
        'Bebidas agregadas': 'Added drinks',
        'Listado de las últimas bebidas consumidas': 'List of the last drinks consumed',
        'En esta sección encontrarás el historial de tus bebidas': 'In this section you will find the history of your drinks',
        'Visita el Home y registra lo qué estás tomando': 'Visit the Home and record what you are taking',
        'Ir a Home': 'Go Home',
        'Busca lo que estás tomando': 'Search what you\'re drinking',
        '¿Qué estás tomando?': 'What are you drinking?',
        Enero: 'January',
        Febrero: 'February',
        Marzo: 'March',
        Abril: 'April',
        Mayo: 'May',
        Junio: 'June',
        Julio: 'July',
        Agosto: 'August',
        Septiembre: 'September',
        Octubre: 'October',
        Noviembre: 'November',
        Diciembre: 'December',
      },
      fr: {
        [str]: str,
        Usuario: "Nom d'utilisateur",
        'Correo electrónico': 'Courrier électronique',
        Contraseña: 'Mot de passe',
        'Iniciar sesión': 'Connexion',
        'Inicia sesión e indica lo que estás tomando para obtener un resultado preciso y en tiempo real, sobre la cantidad de alcohol que hay en tu organismo': 'Connectez-vous et enregistrez ce que vous buvez pour obtenir un résultat précis en direct sur la teneur en alcool dans le sang de votre organisme',
        '¿Olvidaste tu contraseña?': 'Mot de passe oublié?',
        '¿Aún no tienes cuenta?': "Vous n'avez pas encore de compte?",
        '¡Regístrate!': "S'inscrire!",
        'Este campo es requerido': 'Ceci est une entrée obligatoire',
        'Calcula tu nivel de alcohol en la sangre': "Calculez votre taux d'alcoolémie",
        'en tiempo real': 'en temps réel',
        Correo: 'Email',
        Registro: 'Inscription',
        Registrate: "S'inscrire",
        Volver: 'Revenir',
        'Crea una cuenta y comienza a calcular': 'Créez un compte et commencer',
        'de inmediato': 'immédiatement calculer',
        Genero: 'Genre',
        Masculino: 'Mâle',
        Femenino: 'Femelle',
        'Fecha de nacimiento': 'Date de naissance',
        Dia: 'Jour',
        Día: 'Jour',
        Mes: 'Mois',
        Año: 'année',
        Altura: 'Hauteur',
        Peso: 'Poids',
        'Al dar click en Crear cuenta, aceptas los': 'En cliquant sur Créer un compte, vous acceptez les',
        'términos de Alcoholemia': 'termes de Alcoholemia',
        'y que has leído nuestra': 'et vous avez lu notre',
        'Política de privacidad': 'Politique de confidentialité',
        'incluido el': 'y compris',
        'Uso de cookies': "l'utilisation de cookies",
        '¿Ya tienes una cuenta?': 'Vous avez déjà un compte?',
        Bienvenido: 'Bienvenue',
        'Tu cuenta ha sido creada con éxito': 'Votre compte a été créé avec succès',
        'Se envió una contraseña a tu correo electrónico con tu contraseña temporal (verifica la carpeta de spam)': 'Un mot de passe a été envoyé à votre email avec votre mot de passe temporaire (vérifiez le dossier spam)',
        'Recuerda que los resultados que se muestran son aproximaciones': 'Rappelez-vous que les résultats affichés sont des approximations',
        'Bebe con resposabilidad': 'Buvez de façon responsable',
        'Cerrar sesión': 'Se déconnecter',
        puntos: 'points',
        '8 puntos a las': '8 points à',
        'Sobrio a las': 'Sobre à',
        'No se encontraron resultados para': 'Aucun résultat pour',
        Cantidad: 'Quantité',
        'Cantidad consumida': 'Quantité consommée',
        'Registrar bebida': 'S\'inscrire boisson',
        'Bebida añadida': 'Boisson ajoutée',
        'La bebida ha sido registrada exitosamente': 'La boisson a été enregistrée avec succès',
        'Esta bebida te sumó': 'Cette boisson vous a ajouté',
        Cerrar: 'Proche',
        Entendido: "C'est compris",
        'Últimas bebidas': 'Derniers verres',
        'Bebidas agregadas': 'Boissons ajoutées',
        'Listado de las últimas bebidas consumidas': 'Liste des dernières boissons consommées',
        'En esta sección encontrarás el historial de tus bebidas':
          "Dans cette rubrique vous trouverez l'historique de vos boissons",
        'Visita el Home y registra lo qué estás tomando':
          'Visitez la maison et notez ce que vous prenez',
        'Ir a Home': 'Aller à Home',
        'Busca lo que estás tomando': 'Cherche ce que tu bois',
        '¿Qué estás tomando?': 'Que bois-tu?',
        Enero: 'Janvier',
        Febrero: 'Février',
        Marzo: 'Mars',
        Abril: 'Avril',
        Mayo: 'Mai',
        Junio: 'Juin',
        Julio: 'Juillet',
        Agosto: 'Août',
        Septiembre: 'Septembre',
        Octubre: 'Octobre',
        Noviembre: 'Novembre',
        Diciembre: 'Décembre',
      },
    }

    return translations[deviceLanguage][str]
  }
}

export default useTranslate
