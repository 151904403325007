import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { TouchableOpacity } from 'react-native-gesture-handler'

import AlcoholemiaBadge from '../atoms/AlcoholemiaBadge'
import Text from '../atoms/Text'

import { getAlcoholemiaState } from '../../redux/slices/alcoholemiaSlice'
import { setModalData } from '../../redux/slices/modalSlice'
import { useTranslate } from '../../hooks'

import { Drink } from '../../types/apiResponseTypes'

const styles = StyleSheet.create({
  badgesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  text: {
    color: 'grey',
  },
})

interface DrinkCustom extends Drink {
  quantity: number
}

const LastDrinks = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const { addedDrinks } = useSelector(getAlcoholemiaState)
  const uniqueAddedDrinks = [
    ...new Map(
      addedDrinks.map(
        item =>
          [item.marca, item] && [item.tipo, item] && [item.variante, item] && [item.quantity, item]
      )
    ).values(),
  ]

  const setModal = (data: DrinkCustom) =>
    dispatch(setModalData({ data: { ...data, quantity: data.quantity }, modalName: 'viewDrink' }))

  return (
    <>
      {addedDrinks.length > 0 && (
        <>
          <Text variant="h2" css={{ paddingTop: 25, paddingBottom: 5 }}>
            {translate('Últimas bebidas')}
          </Text>
          <View style={styles.badgesContainer}>
            {uniqueAddedDrinks.map(drink => (
              <TouchableOpacity onPress={() => setModal(drink)} key={drink.time.timestamp}>
                <AlcoholemiaBadge
                  margin={1}
                  children={`${drink.tipo} ${drink.marca} ${drink.variante} - ${drink.presentacion} (${drink.quantity}ml)`}
                  variant="solid"
                />
              </TouchableOpacity>
            ))}
          </View>
        </>
      )}
    </>
  )
}

export default LastDrinks
