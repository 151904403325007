import axios from 'axios'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { addSearchReducer, clearSearchArrayReducer } from '../reducers/busquedaReducers'

import getApiAdress from '../../utils/apiConfig'

import {
  BusquedaStatus,
  BusquedaTerm,
  RootState,
  SaveSearchTermPayload,
} from '../../types/reduxTypes'

const SLICE_NAME = 'busqueda'

export const busquedaInitState: BusquedaStatus = {
  history: [],
}

export const UPLOAD_TERM_ACTION_NAME: string = 'BUSQUEDA_UPLOAD_TERM_ACTION'

export const saveSearchTerm = createAsyncThunk(
  UPLOAD_TERM_ACTION_NAME,
  async ({ term, token }: SaveSearchTermPayload, { dispatch, getState }) => {
    const { busqueda, user } = <RootState>getState()

    const data: BusquedaTerm[] = [{ term, timestamp: new Date() }, ...busqueda.history]
    try {
      const response = await axios({
        url: `${getApiAdress().alcoholemiaApi}searchTerm/?idioma_id=${user.idioma.id}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ['Authorization']: `token ${token}`,
        },
        withCredentials: true,
        data,
      })

      if (response.data > 0) {
        dispatch(clearSearch())
      }
    } catch (error) {
      dispatch(addSearch(data))
    }
  }
)

const BusquedaSlice = createSlice({
  name: SLICE_NAME,
  initialState: busquedaInitState,
  reducers: {
    addSearch: addSearchReducer,
    clearSearch: clearSearchArrayReducer,
  },
})

export const { addSearch, clearSearch } = BusquedaSlice.actions

export const getBusquedaState = ({ busqueda }: { busqueda: BusquedaStatus }) => busqueda

export default BusquedaSlice.reducer
