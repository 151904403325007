import React, { useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Linking, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import AlcoholemiaBackground from '../atoms/AlcoholemiaBackground'
import AlcoholemiaDivider from '../atoms/AlcoholemiaDivider'
import AlcoholemiaScrollView from '../atoms/AlcoholemiaScrollView'
import AlcoholemiaImage from '../atoms/AlcoholemiaImage'
import LoginForm from '../organisms/LoginForm'
import Text from '../atoms/Text'

import { getUserState } from '../../redux/slices/userSlice'
import { useToast, useTranslate } from '../../hooks'
import { isWeb } from '../../utils/platformUtils'

import { NavigationTypes } from '../../types/ScreenTypes'

const styles = StyleSheet.create({
  logo: {
    height: isWeb ? '35%' : 150,
    width: isWeb ? '100%' : 150,
    maxWidth: 300,
  },
  sloganContainer: {
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 15,
    textShadowColor: 'rgba(255, 255, 255, 0.45)',
    textAlign: 'center',
    width: 300,
  },
  sloganTxtP1: {
    color: '#2d99cc',
  },
  loginDescription: {
    fontFamily: 'helvetica',
    marginBottom: 10,
    textAlign: 'center',
    marginTop: isWeb ? 10 : 0,
  },
  hyperlinkText: {
    color: '#2d99cc',
  },
  divider: {
    padding: 1,
    marginTop: 5,
    marginBottom: 5,
  },
})

/**
 * ### Renders the Login Screen
 */

const Login: React.FC = () => {
  const navigation = useNavigation<NavigationTypes>()
  const { status, message } = useSelector(getUserState)
  const toast = useToast('user')
  const translate = useTranslate()

  useEffect(() => {
    toast(status, message)
  }, [status])

  const logo = require('../../../assets/logo.png')

  return (
    <AlcoholemiaBackground>
      <>
        <AlcoholemiaImage resizeMode="center" alt="Alcoholemia" style={styles.logo} source={logo} />

        <AlcoholemiaScrollView>
          <Text variant="h3" css={styles.sloganContainer}>
            <Text variant="h3" css={styles.sloganTxtP1}>
              {translate('Calcula tu nivel de alcohol en la sangre')}{' '}
            </Text>
            <Text variant="h3">{translate('en tiempo real')}</Text>
          </Text>

          <Text variant="h7" css={styles.loginDescription}>
            {translate(
              'Inicia sesión e indica lo que estás tomando para obtener un resultado preciso y en tiempo real, sobre la cantidad de alcohol que hay en tu organismo'
            )}
            .
          </Text>

          <LoginForm />

          <Text variant="body" css={styles.hyperlinkText} onPress={() => Linking.openURL('#')}>
            {translate('¿Olvidaste tu contraseña?')}
          </Text>

          <AlcoholemiaDivider style={styles.divider} width="90%" />

          <Text variant="body">
            {translate('¿Aún no tienes cuenta?')}
            <Text
              variant="body"
              css={styles.hyperlinkText}
              onPress={() => navigation.navigate('Register')}
            >
              {' '}
              {translate('¡Regístrate!')}
            </Text>
          </Text>

          <AlcoholemiaDivider style={styles.divider} width="90%" />

          <Text variant="bodyOrbitronBold">
            Alcoholemia <Text variant="bodyBold">©{new Date().getFullYear()}</Text>
          </Text>
        </AlcoholemiaScrollView>
      </>
    </AlcoholemiaBackground>
  )
}

export default Login
