import React from 'react'
import { Controller } from 'react-hook-form'
import { Icon, IInputProps as InputPropsNativeBase, Input } from 'native-base'
import { StyleSheet, TextStyle, View } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'

import Text from './Text'

import { useTranslate } from '../../hooks'

import { TextVariantsTypes } from '../../types/CustomComponents'

interface AlcoholemiaInputPropsBase extends InputPropsNativeBase {
  name: string
  form: any
  required?: boolean
  css?: TextStyle
  labelCSS?: TextStyle
  defaultValue?: string
  placeholder?: string
  label?: string
  type?: string
  labelVariant?: TextVariantsTypes
}

type AlcoholemiaInputProps = Omit<AlcoholemiaInputPropsBase, 'style'>

const innerStyles = StyleSheet.create({
  container: { flexDirection: 'column', flexWrap: 'wrap' },
  mainInputStyles: {
    marginBottom: 10,
    borderColor: '#eaeaea',
    padding: 3,
    height: 40,
    borderRadius: 8,
    backgroundColor: '#fafafa',
    fontFamily: 'helvetica',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
  },
})

/**
 * This component renders an input handled by React-Hook-Form with its control attribute.
 * @param form This must be the object that the useForm hook from RHF returns[OBJECT][REQUIRED]
 * @param name The name that the input will have[STRING][REQUIRED]
 * @param required Determinates if this field is required(DEFAULT false)[BOOLEAN]
 * @param defaultValue Determinates with which value going to renders this field[STRING]
 * @param css Determinates the input styles[OBJECT]
 * @param labelCSS Determinates the input label styles[OBJECT]
 * @param placeholder Determinates the placeholder of the input[STRING]
 * @param label Determinates if the label should be showed[STRING]
 * @param type Determinates the type of text of the input(DEFAULT: "text")[STRING]
 * @param labelVariant Determinates which variant will be used in the input label [STRING]
 * @type TextVariantsTypes:
  | 'uber'
  | 'hero'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'body'
  | 'bodyBold'
  | 'bodyBoldUnderlined'
  | 'bodyOrbitron'
  | 'bodyOrbitronBold'
  | 'caption'
  | 'small'
  | 'smallBold'
  | 'smallBoldUnderlined'
  | 'tiny'
  | 'tinyBold'
  | 'tinyItalic'
  | 'tinyUnderlined'
  | 'tinyBoldUnderlined'
 *
 * @summary Besides you can pass any other attributes that the "TextInput" supports
 */

const AlcoholemiaInput = ({
  form,
  css = {},
  name,
  required = false,
  defaultValue,
  placeholder = '',
  label = '',
  labelVariant = 'body',
  type = 'text',
  ...alcoholemiaInputProps
}: AlcoholemiaInputProps) => {
  const { control } = form
  const translate = useTranslate()

  return (
    <View style={innerStyles.container}>
      {label ? (
        <Text css={alcoholemiaInputProps.labelCSS} variant={labelVariant}>
          {label}
        </Text>
      ) : null}
      <Controller
        control={control}
        rules={{
          required: required,
        }}
        render={({
          field: { onChange, onBlur, value, ...field },
          fieldState: { invalid, error },
        }) => (
          <>
            <Input
              onChangeText={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              value={value.toString()}
              type={type}
              style={[css]}
              {...field}
              {...alcoholemiaInputProps}
            />
            {error && (
              <View>
                <Text variant="tiny" css={innerStyles.errorMessage}>
                  {translate('Este campo es requerido')}.
                </Text>
              </View>
            )}
          </>
        )}
        name={name}
        defaultValue={defaultValue}
      />
    </View>
  )
}

export default AlcoholemiaInput
