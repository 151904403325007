import React from 'react'
import { Box, IBoxProps } from 'native-base'

interface AlcoholemiaBoxProps extends IBoxProps {
  children: React.ReactChild
}

const AlcoholemiaBox = ({ children, ...boxProps }: AlcoholemiaBoxProps) => {
  return (
    <Box
      rounded="lg"
      overflow="hidden"
      borderColor="coolGray.200"
      borderWidth="1"
      _dark={{
        borderColor: 'coolGray.600',
        backgroundColor: 'gray.700',
      }}
      _web={{
        shadow: 2,
        borderWidth: 0,
      }}
      _light={{
        backgroundColor: 'gray.50',
      }}
      {...boxProps}
    >
      {children}
    </Box>
  )
}

export default AlcoholemiaBox
