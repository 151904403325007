import React from 'react'
import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { useSelector } from 'react-redux'

import AlcoholemiaAlert from '../atoms/AlcoholemiaAlert'
import PrivateScreens from './PrivateScreens'
import PublicScreens from './PublicScreens'

import { getUserState } from '../../redux/slices/userSlice'
import { UserState } from '../../types/reduxTypes'

const alcoholemiaTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    text: 'white',
    border: '#34aacc',
    card: '#34aacc',
  },
}

const ScreensRoutes: React.FC = () => {
  const {
    auth: { isAuthenticated },
  }: UserState = useSelector(getUserState)

  return (
    <NavigationContainer theme={alcoholemiaTheme}>
      {isAuthenticated ? <PrivateScreens /> : <PublicScreens />}
      <AlcoholemiaAlert />
    </NavigationContainer>
  )
}

export default ScreensRoutes
