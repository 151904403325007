import React from 'react'
import { useForm } from 'react-hook-form'
import { Linking, StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import AlcoholemiaButton from '../atoms/AlcoholemiaButton'
import AlcoholemiaSelect from '../atoms/AlcoholemiaSelect'
import AlcoholemiaInput from '../atoms/AlcoholemiaInput'
import Text from '../atoms/Text'

import { createRegister } from '../../redux/slices/userSlice'
import { useTranslate } from '../../hooks'
import { isDevelopment, isIOS, isWeb } from '../../utils/platformUtils'

import { RegisterFormData, SelectOptionsTypes } from '../../types/CustomComponents'

const styles = StyleSheet.create({
  formTextInput: {
    marginTop: 5,
    width: '100%',
    backgroundColor: 'white',
  },
  inputLabel: { textAlign: 'center' },
  formBirthdayInput: {
    marginTop: 10,
    width: 95,
    backgroundColor: 'white',
  },
  birthdayLabel: {
    marginTop: 10,
    textAlign: 'center',
  },
  formHeightWeightContainer: {
    justifyContent: 'center',
    marginTop: 10,
  },
  formHeightWeightInput: {
    marginTop: 5,
    marginBottom: 10,
    width: '100%',
    backgroundColor: 'white',
  },
  marginTop: { marginTop: 10 },
  formSelect: {
    marginBottom: isIOS ? -70 : 0,
    borderRadius: 8,
    height: isWeb ? 40 : 'auto',
  },
  birthdaySection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  disclaimer: {
    textAlign: 'justify',
  },
  hyperlinkText: {
    color: '#2d99cc',
  },
})

const defaultValues = isDevelopment
  ? {
      email: 'luisus@luisus.com',
      gender: 'Masculino',
      birthday: { day: '15', month: '10', year: '1989' },
      height: '185',
      weight: '106',
    }
  : {
      email: '',
      gender: 'Masculino',
      birthday: { day: '1', month: '1', year: '2003' },
      height: '',
      weight: '',
    }

/**
 * ### Renders the Register form
 */

const RegisterForm: React.FC = () => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const form = useForm({ defaultValues })
  const { handleSubmit } = form

  const handleRegister = ({ birthday, email, gender, height, weight }: RegisterFormData) => {
    const { day, month, year } = birthday
    const formData = {
      email,
      birthday: `${year}-${month}-${day}`,
      gender,
      height,
      weight,
    }
    dispatch(createRegister(formData))
  }

  const genderOptions: SelectOptionsTypes[] = [
    { label: translate('Masculino'), value: 'Masculino' },
    { label: translate('Femenino'), value: 'Femenino' },
  ]

  // happy path 🙂 🔻
  const days: SelectOptionsTypes[] = []
  for (var i = 1; i < 31 + 1; i++) {
    days.push({ label: '' + i, value: ('0' + i).slice(-2) })
  }
  const dayOptions = days

  const monthOptions: SelectOptionsTypes[] = [
    { label: translate('Enero'), value: '01' },
    { label: translate('Febrero'), value: '02' },
    { label: translate('Marzo'), value: '03' },
    { label: translate('Abril'), value: '04' },
    { label: translate('Mayo'), value: '05' },
    { label: translate('Junio'), value: '06' },
    { label: translate('Julio'), value: '07' },
    { label: translate('Agosto'), value: '08' },
    { label: translate('Septiembre'), value: '09' },
    { label: translate('Octubre'), value: '10' },
    { label: translate('Noviembre'), value: '11' },
    { label: translate('Diciembre'), value: '12' },
  ]

  const years: SelectOptionsTypes[] = []
  for (var i = 1900; i < new Date().getFullYear() + 1; i++) {
    years.push({ label: '' + i, value: i })
  }
  const yearsOptions = years.sort((acc, curr) => (acc.value > curr.value ? -1 : 1))
  // happy path 🙂 🔺

  return (
    <View>
      <AlcoholemiaInput
        form={form}
        name="email"
        required={true}
        css={styles.formTextInput}
        label={translate('Correo')}
        labelCSS={styles.inputLabel}
        textContentType="emailAddress"
        keyboardType="email-address"
        variant="filled"
      />
      <AlcoholemiaSelect
        name="gender"
        options={genderOptions}
        label={translate('Genero')}
        itemColor="white"
        form={form}
        style={styles.formSelect}
        labelCSS={styles.inputLabel}
      />
      <Text css={styles.birthdayLabel} variant="body">
        {translate('Fecha de nacimiento')}
      </Text>
      <View style={styles.birthdaySection}>
        {/* <AlcoholemiaInput
          form={form}
          name="birthday.day"
          required={true}
          css={styles.formBirthdayInput}
          keyboardType="number-pad"
          placeholder={translate('Dia')}
          variant="filled"
        /> */}
        <AlcoholemiaSelect
          name="birthday.day"
          options={dayOptions}
          label={translate('Día')}
          itemColor="white"
          form={form}
          style={styles.formSelect}
          labelCSS={styles.inputLabel}
        />
        {/* <AlcoholemiaInput
          form={form}
          name="birthday.month"
          required={true}
          css={styles.formBirthdayInput}
          keyboardType="number-pad"
          placeholder={translate('Mes')}
          variant="filled"
        /> */}
        <AlcoholemiaSelect
          name="birthday.month"
          options={monthOptions}
          label={translate('Mes')}
          itemColor="white"
          form={form}
          style={styles.formSelect}
          labelCSS={styles.inputLabel}
        />
        {/* <AlcoholemiaInput
          form={form}
          name="birthday.year"
          required={true}
          css={styles.formBirthdayInput}
          keyboardType="number-pad"
          placeholder={translate('Año')}
          variant="filled"
        /> */}
        <AlcoholemiaSelect
          name="birthday.year"
          options={yearsOptions}
          label={translate('Año')}
          itemColor="white"
          form={form}
          style={styles.formSelect}
          labelCSS={styles.inputLabel}
        />
      </View>
      <View style={styles.formHeightWeightContainer}>
        <AlcoholemiaInput
          form={form}
          name="height"
          label={`${translate('Altura')} (cm)`}
          required={true}
          css={styles.formHeightWeightInput}
          labelCSS={styles.inputLabel}
          keyboardType="number-pad"
          variant="filled"
        />
        <AlcoholemiaInput
          form={form}
          name="weight"
          label={`${translate('Peso')} (kg)`}
          required={true}
          css={styles.formHeightWeightInput}
          labelCSS={styles.inputLabel}
          keyboardType="number-pad"
          variant="filled"
        />
      </View>
      <Text variant="tiny" css={styles.disclaimer}>
        {translate('Al dar click en Crear cuenta, aceptas los')}{' '}
        <Text onPress={() => Linking.openURL('#')} css={styles.hyperlinkText} variant="tiny">
          {translate('términos de Alcoholemia')}
        </Text>{' '}
        {translate('y que has leído nuestra')}{' '}
        <Text onPress={() => Linking.openURL('#')} css={styles.hyperlinkText} variant="tiny">
          {translate('Política de privacidad')}
        </Text>
        , {translate('incluido nuestro')}{' '}
        <Text onPress={() => Linking.openURL('#')} css={styles.hyperlinkText} variant="tiny">
          {translate('Uso de cookies')}
        </Text>
        .
      </Text>
      <AlcoholemiaButton
        style={styles.marginTop}
        title={`${translate('Registrate')}`}
        onPress={handleSubmit(handleRegister)}
      />
    </View>
  )
}

export default RegisterForm
